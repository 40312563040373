import { SignIn } from '@clerk/remix'

import { ClerkLayout } from './sign-up.$'

export default function SignInPage() {
	return (
		<>
			<div className="p-4 bg-indigo-950 top-0 left-0 w-full mb-7">
				<h2 className="text-xl font-sm text-center text-indigo-200">
					Start Your Reveal Journey.
				</h2>
			</div>
			<div className="mx-auto align-middle w-max mt-20">
				<SignIn
					appearance={{
						layout: ClerkLayout,
					}}
					fallbackRedirectUrl="/"
				/>
			</div>
		</>
	)
}
